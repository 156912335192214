<!-- 全球展会 -->
<template>
	<div class="ExhibitorList">
		<div v-if="config.img&&config.img.isShow">
			<TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
		</div>
		<!--搜索-->
		<div class="wCenter" v-if="config.c1&&config.c1.isShow">
			<div class="searchMain">
				<p class="title">展会搜索</p>
				<Form ref="formExhibition" :model="formExhibition">
					<FormItem prop="industryCategoryId" class="select">
						<Select transfer v-model="formExhibition.industryCategoryId" placeholder="请选择行业分类" clearable>
							<Option :value="item.value" v-for="(item,index) in selectIndustryCategoryList" :key="index">
								{{item.label}}
							</Option>
						</Select>
					</FormItem>
					<FormItem prop="marketProfileId" class="select">
						<Select transfer v-model="formExhibition.marketProfileId" placeholder="请选择市场区域" clearable>
							<Option :value="item.value" v-for="(item,index) in selectMarketProfileList" :key="index">
								{{item.label}}
							</Option>
						</Select>
					</FormItem>
					<FormItem prop="yearMonth" class="select">
						<Select transfer v-model="formExhibition.yearMonth" placeholder="请选择展会时间" clearable>
							<Option :value="item.value" v-for="(item,index) in selectExhibitionTimeList" :key="index">
								{{item.label}}
							</Option>
						</Select>
					</FormItem>
					<FormItem class="product">
						 <AutoComplete
						        v-model="formExhibition.keyword"
						        :data="keywordsList"
						        @on-search="getNameCnListFun"
						        placeholder="请输入要搜索的内容"
								 @keydown.enter.native.prevent="search" 
								 clearable
						        ></AutoComplete>
						</ul>
					</FormItem>
					<FormItem class="submit">
						<Button type="primary" @click="search">搜索</Button>
					</FormItem>
				</Form>

				<div class="textListt">
					<span class="fb">热搜词:</span>
					<div>
						<span v-for="(item,index) in hotKeyList" :key="index"
							@click="searchByKeyword(item.keyword)">{{item.keyword}}</span>
					</div>
				</div>
			</div>
			<div class="count">
				<p><span v-if="showSearch">已搜索 {{total}} 条信息</span></p>
				<p>
					<img src="../../assets/iconList.png" @click="showType(1)"
						:style="showList?'':'filter: grayscale(100%)'" />
					<img src="../../assets/iconPic.png" @click="showType(2)"
						:style="showPic?'':'filter: grayscale(100%)'" />
				</p>
			</div>
		</div>
		<!--搜索-->
		<!--展会列表-->
		<div class="wCenter" v-if="exhiList && exhiList.length>0">
			<div class="ExhibitorListPic" v-if="showPic">
				<div class="ExhibitorListPicItem" v-for="(item,index) in exhiList" :key="index"
					@click="goDetail(item.id)">
					<div class="pic">
						<img v-lazy="item.logoUrl" :key="item.logoUrl" class="lazyPic" lazy="loading" />
					</div>
					<div class="contentMain">
						<p class="conpanyName">{{item.title?item.title:"暂无展会中文名称"}}</p>
						<p class="content">{{item.titleEn?item.titleEn:"暂无展会英文名称"}}</p>

						<p class="address" style="margin-top: 0.4rem;">
							<img src="../../assets/time.png" />
							{{item.time}}
						</p>
						<p class="address">
							<img src="../../assets/location.png" />
							{{item.address?item.address:"暂无地址"}}
						</p>
					</div>
				</div>
			</div>
			<div class="ExhibitorListList" v-if="showList">
				<div class="ExhibitorListListItem" v-for="(item,index) in exhiList" :key="index"
					@click="goDetail(item.id)">
					<div class="right">
						<div class="rightL">
							<div class="pic">
								<img v-lazy="item.logoUrl" :key="item.logoUrl" class="lazyPic" lazy="loading" />
							</div>
						</div>
						<div class="rightR">
							<p class="conpanyName">{{item.title?item.title:"暂无展会中文名称"}}</p>
							<p class="conpanyName">{{item.titleEn?item.titleEn:"暂无展会英文名称"}}</p>
							<p class="time">{{item.time}}</p>
							<div class="lable" v-if="item.labelList&&item.labelList.length>0">
								<Label v-for="(items,index1) in item.labelList" :key="index1" :data='items'></Label>
							</div>
						</div>
					</div>
					<div class="bottom">
						<p class="content" v-html="item.content"></p>
						<div class="rightRB">
							<p class="address" v-if="item.address">
								<img src="../../assets/location.png" />
								{{item.address}}
							</p>
							<p class="address" v-if="item.website">
								<img src="../../assets/address.png" />
								{{item.website | httpFilter}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wCenter" v-else>
			<div class="noData">
				<img src="../../assets/null.png" />
			</div>
		</div>
		<div class="tabFoot" v-if="config.c1&&config.c1.isShow">
			<Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize"
				@on-change="changePage" @on-page-size-change="changePageSize" :page-size-opts="[12,24,48]" size="small"
				show-total show-elevator show-sizer></Page>
		</div>
		<!--展商列表-->
	</div>
</template>

<script>
	import util from "@/libs/util.js";
	import {
		getPageConfigByCode,
		getMarketProfileList,
		getIndustryCategoryList,
		getExhibitionTimeList,
		getHotKeyList,
		getNewsList,
		getExhibitionYearMonthList,
		getNameCnList
	} from '@/api/api'
	import TopImg from '@/components/topImg'
	import Label from '@/components/hotLabel'
	export default {
		name: '',
		components: {
			TopImg,
			Label
		},
		data() {
			return {
				config: {},
				showList: true,
				showPic: false,
				hotKeyList: '',
				formExhibition: {
					industryCategoryId: '',
					marketProfileId: '',
					yearMonth: '',
					keyword: '',
				},
				searchForm: {
					type: 1,
					pageNumber: 1,
					pageSize: 12,
					keyword: '',
					industryCategoryId: '', //行业分类
					marketProfileId: '', //市场区域
					yearMonth: '', //展会时间
				},
				exhiList: '',
				total: null,
				// 行业分类
				selectIndustryCategoryList: null,
				// 市场区域
				selectMarketProfileList: null,
				// 展会时间
				selectExhibitionTimeList: null,
				keywordsList:[],
				showSearch:false
			}
		},
		computed: {
			formExhibitionFromHome() {
				return this.$store.state.data.formExhibition
			}
		},
		filters:{
			httpFilter:function(value){
				value = value.replace(/^(http(s)?)(:\/\/)/,'')
				value =  value.replace(/(.*)(\/){1}$/,'$1');
				return value;
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.getPageConfigByCode()
				this.getIndustryCategoryList()
				this.getMarketProfileList()
				this.getExhibitionTimeList()
				this.getExhiListFromParams()
				this.getHotKeyList()
			},
			getExhiListFromParams() {
				Object.assign(this.formExhibition, this.formExhibitionFromHome)
				Object.assign(this.searchForm, this.formExhibitionFromHome)
				this.getExhiList()
			},
			// 页面配置
			getPageConfigByCode() {
				const params = {
					code: 'exhibitionList/index',
				}
				getPageConfigByCode(params).then(res => {
					if (res.success) {
						this.config = JSON.parse(res.result)
					}
				})
			},
			changePage(v) {
				this.searchForm.pageNumber = v
				this.getExhiList()
			},
			changePageSize(v) {
				this.searchForm.pageSize = v
				this.getExhiList()
			},
			// 获取行业分类
			getIndustryCategoryList() {
				getIndustryCategoryList().then(res => {
					if (res.success) {
						this.selectIndustryCategoryList = res.result
					}
				})
			},
			// 获取市场区域
			getMarketProfileList() {
				getMarketProfileList().then(res => {
					if (res.success) {
						this.selectMarketProfileList = res.result
					}
				})
			},
			// 获取搜索的展会时间
			getExhibitionTimeList() {
				getExhibitionYearMonthList().then(res => {
					if (res.success) {
						this.selectExhibitionTimeList = res.result
					}
				})
			},

			//获取热搜词列表
			getHotKeyList() {
				getHotKeyList({
					type: 1
				}).then(res => {
					if (res.success) {
						this.hotKeyList = res.result
					}
				})
			},
			//板式更换
			showType(e) {
				if (e == 1) {
					this.showList = true
					this.showPic = false
				} else {
					this.showPic = true
					this.showList = false
				}
			},
			
			//获取展会列表
			getExhiList() {
				//行业分类及市场选择，选择为不限时，不传值，值为空 不限为14
				this.searchForm.industryCategoryId = this.formExhibition.industryCategoryId==14?"":this.formExhibition.industryCategoryId
				this.searchForm.marketProfileId = this.formExhibition.marketProfileId==14?"":this.formExhibition.marketProfileId
				getNewsList(this.searchForm).then(res => {
					if (res.success) {
						this.exhiList = res.result.records
						this.total = res.result.total
					}
				})
			},
			//热搜词搜索
			searchByKeyword(e) {
				this.searchForm.keyword = e
				getNewsList(this.searchForm).then(res => {
					if (res.success) {
						this.exhiList = res.result.records
						this.total = res.result.total
					}
				})
			},
			//搜索
			search() {
				this.showSearch=true
				this.searchForm.keyword = this.formExhibition.keyword
				//行业分类及市场选择，选择为不限时，不传值，值为空 不限为14
				this.searchForm.industryCategoryId = this.formExhibition.industryCategoryId==14?"":this.formExhibition.industryCategoryId
				this.searchForm.marketProfileId = this.formExhibition.marketProfileId==14?"":this.formExhibition.marketProfileId
				this.searchForm.yearMonth = this.formExhibition.yearMonth
				getNewsList(this.searchForm).then(res => {
					if (res.success) {
						this.exhiList = res.result.records
						this.total = res.result.total
					}
				})
			},
			//展会详情
			goDetail(e) {
				util.pageChange(this.$router, `/exhibitionDetail`, {
					id: e
				}, '_blank');

			},
			//联想搜索
			getNameCnListFun(){
				
				if(!this.formExhibition.keyword){
					this.keywordsList=[];
					return;
				}
				getNameCnList(this.formExhibition.keyword).then(res => {
					if (res.success) {
						this.keywordsList =res.result
					}
				})
			}




		},
		watch: {
		  'formExhibition.keyword'(val) {
		   if(!val){
		   	this.keywordsList=[];
		   }
		  },
		},
	}
</script>

<style lang="scss" scoped>
	.searchMain {
		background: #eeeeee;
		padding: 0.4rem 0.5rem;
		margin-top: 0.3rem;

		@media (max-width: 767px) {
			margin-top: .3rem;
		}

		.title {
			color: #333333;
			font-size: 0.24rem;
			margin-bottom: 0.3rem;

			@media (max-width: 767px) {
				font-size: 0.4rem;
			}
		}

		.ivu-form {
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 767px) {
				display: block;
			}
		}

		.select {
			width: 3.2rem;
			margin-right: 0.55rem;
		}

		.ivu-form-item {
			@media (max-width: 767px) {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}

		.product {
			width: 10.7rem;

			@media (max-width: 767px) {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}

		.submit {
			width: 1rem;
			margin-left: 0.2rem;

			@media (max-width: 767px) {
				width: 50%;
				margin-left: 25%;
			}

			.ivu-btn-primary {
				width: 1rem;
				height: 0.5rem;
				background-color: #333333;
				border-color: #333333;

				@media (max-width: 767px) {
					width: 100%;
					height: 0.5rem * 1.5;
				}
			}
		}

		.textListt {
			display: flex;
			flex-wrap: wrap;

			div {
				flex: 0 0 10rem;
				display: flex;
				flex-wrap: wrap;
			}

			@media (max-width: 767px) {
				margin-top: 0.2rem;
			}

			span {
				color: #727272;
				font-size: 0.24rem;
				margin-right: 0.6rem;
				margin-bottom: 0.1rem;
				cursor: pointer;

				@media (max-width: 767px) {
					margin-right: 0.4rem;
					font-size: 0.38rem;
				}
			}
		}
	}

	.count {
		color: #727272;
		font-size: 0.24rem;
		margin: 0.4rem 0;
		display: flex;
		justify-content: space-between;

		@media (max-width: 767px) {
			font-size: 0.38rem;
		}

		img {
			margin-left: 0.2rem;
			cursor: pointer;
			width: 0.3rem;

			@media (max-width:767px) {
				width: .5rem;
				margin-left: 0.5rem;
			}
		}
	}

	.lable {
		margin-top: 0.1rem;
		display: flex;
		flex-wrap: wrap;
	}

	.ExhibitorListPic {
		margin-top: 0.2rem;
		display: flex;
		flex-wrap: wrap;

		.ExhibitorListPicItem {
			box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
			width: 4rem;
			margin: 0 0.5rem 0.5rem 0;
			cursor: pointer;

			&:nth-child(3n) {
				margin-right: 0;
			}

			&:nth-last-child(1),
			&:nth-last-child(2),
			&:nth-last-child(3) {
				margin-bottom: 0;
				padding-bottom: 0;
			}

			@media (max-width: 767px) {
				$w: 100%;
				$gutter: (100% - $w * 2);
				width: $w;
				margin: 0 0 3% 0;

				&:nth-child(3n) {
					margin-right: 0;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}

				box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
			}

			.pic {
				width: 4rem;
				height: 2rem;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				border-bottom: 0.01rem solid #e1e1e1;

				@media (max-width: 767px) {
					width: 100%;
					height: 6rem;
					padding: 0.3rem;
				}

				img {
					max-width: 90%;
					max-height: 90%;
					width: auto !important;
				}
			}

			.contentMain {
				padding: 0.3rem 0.24rem 0 0.24rem;

				.conpanyName {
					color: #ff1932;
					font-size: 0.24rem;
					// height: 0.7rem;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;

					@media (max-width: 767px) {
						font-size: 0.44rem;
						// height: 0.9rem;
					}
				}

				.time {
					color: #828282;
					font-size: 0.18rem;
					margin: 0.2rem 0;
				}

				.content {
					color: #333333;
					font-size: 0.2rem;
					// height: 0.6rem;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-top: 0.2rem;

					@media (max-width: 767px) {
						font-size: 0.34rem;
						// height: 0.9rem;
					}
				}

				.address {
					color: #333333;
					font-size: 0.18rem;
					margin: 0.2rem 0;
					line-height: 1.8;

					@media (max-width:767px) {
						font-size: 0.38rem;
					}

					img {
						width: 0.25rem;
						position: relative;
						top: 0.05rem;
						margin-right: 0.2rem;

						@media (max-width:767px) {
							width: 0.35rem;
						}
					}
				}

				.more {
					width: 2.4rem;
					height: 0.53rem;
					line-height: 0.53rem;
					text-align: center;
					background-image: url(../../assets/bannerBtn.png);
					background-size: 100% 100%;
					font-size: 0.24rem;
					color: #ffffff;
					cursor: pointer;
				}
			}
		}
	}

	.ExhibitorListList {
		margin-top: 0.2rem;

		.ExhibitorListListItem {
			cursor: pointer;
			$wL: 1.8rem;
			$wR: calc(100% - 1.8rem);
			// display: flex;
			padding-bottom: 0.4rem;
			border-bottom: 0.001rem solid #cfcdcd;
			margin-bottom: 0.5rem;

			// .left {
			//   width: 1.5rem;
			//   height: 1.9rem;
			//   background: #eeeeee;
			//   display: flex;
			//   justify-content: center;
			//   align-items: center;
			//   flex-direction: column;
			//   color: #333333;
			//   font-size: 0.24rem;
			//   margin-right: 0.4rem;
			//   @media (max-width: 767px) {
			//     width: $wL;
			//     margin-right: 0.2rem;
			//   }
			//   .month {
			//     font-size: 0.34rem;
			//   }
			// }
			.right {
				// width: 12rem;
				display: flex;

				padding-bottom: 0.2rem;

				@media (max-width: 767px) {
					width: $wR;
				}

				.rightL {
					flex: 0 0 2rem;
					height: 1.5rem;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					border: 0.001rem solid #eeeeee;
					margin-right: 0.3rem;

					.pic {
						width: 2rem;
						height: 1.5rem;
						padding: 0.1rem;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					@media (max-width: 767px) {
						padding: 0.06rem;
					}

					img {
						max-width: 100%;
						max-height: 100%;
						width: auto !important;
					}
				}

				.rightR {
					width: 9.7rem;

					@media (max-width: 767px) {
						width: 90%;
					}

					.conpanyName {
						color: #ff1932;
						font-size: 0.22rem;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						margin-bottom: 0.1rem;

						@media (max-width: 767px) {
							font-size: 0.44rem;
						}
					}

					.time {
						color: #727272;
						font-size: 0.18rem;

						@media (max-width: 767px) {
							font-size: 0.38rem;
						}
					}
				}
			}

			.bottom {
				.content {
					color: #333333;
					font-size: 0.2rem;
					height: 0.6rem;
					overflow: hidden;
					/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin: 0.2rem 0;

					@media (max-width: 767px) {
						height: 1.2rem;
						font-size: 0.38rem;
					}
				}

				.rightRB {
					display: flex;
					align-items: center;

					.address {
						color: #333333;
						font-size: 0.18rem;
						word-break: break-all;

						&:first-child {
							margin-right: 0.5rem;
							// flex: 1 1 60%;
						}

						&:last-child {
							// flex: 1 1 40%;
						}

						@media (max-width:767px) {
							font-size: 0.38rem;
						}

						img {
							width: 0.25rem;
							position: relative;
							top: 0.05rem;
							margin-right: 0.2rem;

							@media (max-width: 767px) {
								top: 0;
								width: 0.35rem;
							}
						}
					}

					// .more {
					//   width: 2.4rem;
					//   height: 0.53rem;
					//   line-height: 0.53rem;
					//   text-align: center;
					//   background-image: url(../../assets/bannerBtn.png);
					//   background-size: 100% 100%;
					//   font-size: 0.24rem;
					//   color: #ffffff;
					//   cursor: pointer;
					// }
				}
			}
		}
	}

	.noData {
		text-align: center;
		margin: 0.4rem 0;

		img {
			width: 5rem;
		}
	}

	.tabFoot {
		text-align: center;
		border-top: 0;
	}

	.associate-list {
		background: #ffffff;
		border: 1px solid #dcdee2;
		.associate-item{
			padding:2px 10px;
			border-bottom: 1px solid #dcdee2;
			cursor: pointer;
		}
		.associate-item:hover{
			background: #dcdee2;
			color: #ffffff;
		}
	}
</style>
<style lang="scss" scoped>
	.searchMain {

		.ivu-input,
		::v-deep .ivu-select-selection,
		::v-deep .ivu-select-selected-value,
		::v-deep .ivu-select-placeholder {
			height: 0.5rem !important;
			line-height: 0.5rem !important;

			@media (max-width: 767px) {
				height: 0.5rem * 1.5 !important;
				line-height: 0.5rem * 1.5 !important;
			}
		}

		::v-deep .ivu-input {
			height: 0.5rem !important;

			@media (max-width: 767px) {
				height: 0.5rem * 1.5 !important;
			}
		}

		.ivu-form-item {
			@media (max-width: 767px) {
				margin-bottom: 0.3rem;
			}
		}

		::v-deep .ivu-input-icon {
			    line-height: 0.5rem;
			    font-size: 0.14rem;

			@media (max-width: 767px) {
				line-height: 32px;
				font-size: 16px;
			}
		}
	}
	
</style>
<style>
	.ivu-icon-ios-close:before{
		content: "\f177";
	}
</style>
